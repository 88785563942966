import React from 'react';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import featureFlags from './src/context/featureFlags';
import buildSpecs from './src/Constants.json';
// custom CSS styles
import './src/css/styles/global.module.scss';

const wrapRootElement = ({ element }) => (
    <FeatureToggles features={featureFlags}>{element}</FeatureToggles>
);

// eslint-disable-next-line import/prefer-default-export
export { wrapRootElement };

const shouldUpdateScroll = ({routerProps}) =>
{
    const { search, pathname } = routerProps?.location;
    if ( search === '?page=1' || /\/page\/\d+\/?$/.test( pathname ) )
    {
        const scrollElement = document.getElementById( buildSpecs.build.defaults.GRID_CONTAINER_ID );
        if ( scrollElement.scrollIntoView ) scrollElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
        return false;
    }
    return true;
}
export { shouldUpdateScroll }