module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"debug":{"graphql":{"showQueryOnError":true,"showQueryVarsOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"timeout":90000,"perPage":20,"requestConcurrency":8,"previewRequestConcurrency":3,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"url":"https://www.lowermybills.com/graphql","verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LowerMyBills.com","short_name":"LowerMyBills","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"content/assets/LMBLogoIcon.svg","icons":[{"src":"/page_assets/icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/page_assets/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/page_assets/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/page_assets/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/page_assets/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/page_assets/icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/page_assets/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/page_assets/icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5005846d5628c8bc7434cfffd1e1ab78"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5NTS6DV","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmPreview":"env-30","enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
