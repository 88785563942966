/**
 * Documentation
 * https://confluence.lowermybills.com/pages/viewpage.action?pageId=71802580
 * https://www.npmjs.com/package/@paralleldrive/react-feature-toggles
 */
const featureFlags = [];

featureFlags.push('truth');
featureFlags.push('/learn/');

export default featureFlags;
